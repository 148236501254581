













































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Validators } from 'shared/utils/validator';
@Component({
  name: 'AppAddPayerForm',
  popupOptions: {
    name: 'popup_side popup_payer',
  },
  props: {
    onSubmit: Function,
  },
})
export default class PropertyDataDialog extends Vue {
  // props
  public onSubmit: Function;

  // data
  loading: boolean = false;

  payer: Payer = {
    inn: '',
    kpp: '',
    ogrn: '',
    organisation_name: '',
    legal_address: '',
    head: '',
    bik: '',
    checking_account: '',
    bank_name: '',
    correspondent_account: '',
    fio: '',
    phone: '',
    email: '',
  };

  // methods
  showErrorMessage() {
    this.$noty.error({ text: 'Пожалуйста, корректно заполните все поля' });
  }

  validationConfig(): ValidationConfig {
    const config: ValidationConfig = {
      inn: [ Validators.required, Validators.positiveNumber, Validators.max(12, 'должно быть не более 12 цифр') ],
      ogrn: [ Validators.required, Validators.positiveNumber, Validators.max(15, 'должно быть не более 15 цифр') ],
      organisation_name: [ Validators.required, Validators.min(1), Validators.max(60, 'максимум 60 символов') ],
      legal_address: [ Validators.required, Validators.min(1), Validators.max(300, 'максимум 300 символов') ],
      head: [ Validators.required, Validators.min(2) ],
      bik: [ Validators.required, Validators.min(9, 'должно быть 9 цифр'), Validators.max(9, 'должно быть 9 цифр') ],
      checking_account: [ Validators.required, Validators.positiveNumber, Validators.min(20, 'должно быть 20 цифр'), Validators.max(20, 'должно быть 20 цифр') ],
      bank_name: [ Validators.required, Validators.min(2) ],
      correspondent_account: [ Validators.required, Validators.positiveNumber, Validators.min(20, 'должно быть 20 цифр'), Validators.max(20, 'должно быть 20 цифр') ],
    };
    if (this.payer.kpp) {
      config.kpp = [ Validators.min(9, 'должно быть 9 цифр'), Validators.max(9, 'должно быть 9 цифр') ];
    }
    if (this.payer.fio) {
      config.fio = [ Validators.min(2) ];
    }
    if (this.payer.phone) {
      config.phone = [ Validators.phone ];
    }
    if (this.payer.email) {
      config.email = [ Validators.email ];
    }
    return config;
  }

  async submit() {
    this.loading = true;
    await this.onSubmit(this.payer);
    this.loading = false;
  }
}
